<template>
  <div class="page_auth">
    <el-form :model="auth" class="authForm">
       <h2><i class="el-icon-s-claim"></i> docs.rosperevozki.ru</h2>
      <el-form-item>
        <el-input
          v-model="auth.login"
          placeholder="Введите ваш логин"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="auth.password"
          placeholder="Введите ваш пароль"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button width="100%" type="primary" @click="authSubmit(auth)">Войти</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  data() {
    return {
      auth: {
        login: null,
        password: null,
      },
    };
  },
  created() {
     console.log(this.isLogged);
  },
  methods: {
     ...mapActions(['authSubmit']),
  },
  computed: {
     ...mapGetters(["isLogged"])
  }
};
</script>

<style>
.page_auth {
   display: flex;
   align-items: center;
   height: 100%;
}
.authForm {
   max-width: 350px;
   margin: 0 auto;
   width: 100%;
}
.page_auth h2 {
       text-align: center;
    padding: 20px;
    text-transform: uppercase;
    font-size: 18px;
    color: #409eff;
}

.page_auth i {
   font-size: 38px;
    vertical-align: sub;
}

.page_auth button {
   width: 100%;
}
</style>